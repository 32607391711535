//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ButtonBookmark',
  props: {
    variant: {
      type: String,
      required: false,
      default: null
    },
    pill: {
      type: String,
      required: false,
      default: null
    },
    active: {
      type: Boolean
    }
  }
  // serverCacheKey: props => 'ButtonBookmark::' + JSON.stringify({
  //   variant: props.variant,
  //   pill: props.pill,
  //   active: props.active
  // })
  // serverCacheKey: props => 'ButtonBookmark::' + `${props.variant}_${props.pill}_${props.active}`
}
