//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'LatestUpdateContent',
  props: {
    contentLatest: {
      type: Object,
      default: Object
    }
  },
  // serverCacheKey: props => 'LatestUpdateContent::' + JSON.stringify(props.contentLatest),
  data () {
    return {
      titleConfig: [
        {
          text: 'อัปเดต'
        },
        {
          text: 'ล่าสุด',
          class: 'text-primary'
        }
      ],
      hasNextPage: false,
      showBtnViewMore: true,
      loading: false,
      contents: [],
      payload: {
        pageIndex: 8
      }
    }
  },
  computed: {
    isBooking () {
      return this.$store.state.handle.isBooking
    }
  },
  created () {
    this.contents = cloneDeep(this.contentLatest.items)
  },
  async mounted () {
    if (this.permissionUser() && this.isBooking.pageUuidArray.length) {
      const array = this.isBooking.pageUuidArray.map(v => v.pageUuid)
      if (this.contents.some(content => array.includes(content.pageUuid))) {
        await this.isBooking.pageUuidArray.forEach(async (item) => {
          if (this.contents[item.index] && this.contents[item.index].pageUuid === item.pageUuid) {
            const payload = {}
            payload.userRef = this.$authUser.userRef() // this.$store.state.profile.user ? this.$store.state.profile.user.userRef : ''
            payload.pageUuid = item.pageUuid
            const res = await this.$store.dispatch('contents/getContentBookmark', payload)
            this.contents[item.index].isBookmark = res
          }
        })
        // await this.clearIsBookmark()
      }
    }
  },
  methods: {
    checkrender (index) {
      if (index > -1 && index < 8) {
        if (index === 0 || index === 1) {
          return true
        } else {
          return false
        }
      } else {
        const temp = index % 5
        if ((temp === 3) || (temp === 4)) {
          return true
        }
      }
    },
    async getData () {
      this.loading = true
      await this.$store.dispatch('contents/getKnowledgeLatestSkipTake', this.payload)
      this.update()
    },
    update () {
      // const items = cloneDeep(this.$store.state.contents.contents_latest.items)
      const items = cloneDeep(this.$store.state.contents.contents_latest)
      this.contents = this.contents.length ? [...this.contents, ...items] : items
      this.loading = false
      this.payload.pageIndex += 5
      // let content = this.$store.state.contents.contents_latest
      // if (!('hasNextPage' in content) && !('totalCount' in content)) {
      //   content = this.contentsLatest
      // }

      // this.hasNextPage = content.hasNextPage || false
      this.showBtnViewMore = items.length >= 5
    },
    onClickBookmark (pageUuid, index, event) {
      event.stopPropagation()
      this.handleBookMark(pageUuid, index)
    },
    handleClickTracking (item) {
      this.clickTracking({
        title: item.name,
        pageUuid: item.pageUuid,
        pageType: item.pageType,
        sectionName: 'อัปเดตล่าสุด',
        url: item.url,
        gtmDataLayer: item.gtmDataLayer,
        teamName: process.env.GTM_EVENT_CATEGORY_TEAM
      })
    }
  }
}
